import actionTypes from '../../actions/Profile/actionTypes';

function getInitialState() {
  return {
    isLoading: true,
    isSaving: false,
    hasSaved: false,
    id: null,
    name: null,
    email: null,
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.SAVE_PROFILE_RESET: {
      return getInitialState();
    }
    case actionTypes.FETCH_PROFILE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isSaving: false,
        hasSaved: false,
        id: null,
        name: null,
        email: null,
      };
    }
    case actionTypes.FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        hasSaved: false,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        preferences: action.payload.preferences,
        realName: action.payload.realName,
      };
    }
    case actionTypes.FETCH_PROFILEVERIFIED_SUCCESS: {
      return {
        ...state,
        verified: action.payload.verified,
      };
    }
    case actionTypes.SAVE_PROFILE_REQUEST: {
      return {
        ...state,
        isLoading: false,
        isSaving: true,
        hasSaved: false,
      };
    }
    case actionTypes.SAVE_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        hasSaved: true,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        realName: action.payload.realName,
      };
    }
    case actionTypes.SAVE_PROFILE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        hasSaved: false,
      };
    }
    default:
      return state;
  }
}
