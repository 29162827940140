import { connect } from 'react-redux';
import VerticalExpandButton from './VerticalExpandButton';

function mapStateToProps(state) {
  return {
    theme: state.ui.theme.currentTheme || state.ui.theme.defaultTheme,
  };
}

export default connect(mapStateToProps)(VerticalExpandButton);
