export function parseLeaderboardVisibility(apiValue) {
  // The API returns the leaderboard visibility in the form of a comma delimited (space suffixed) string, e.g. "Name, Credits".
  // This function converts that into a an array with the individual values.

  if (!apiValue) {
    return [];
  }

  const values = apiValue.split(',').map(x => x.trim());

  if (values.includes('None')) {
    return [];
  }

  return values;
}