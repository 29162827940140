import actionTypes from '../../../actions/Entities/Contract/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
    idsByMarketId: {},
  };
}

function reduce(state, contract) {
  const nextState = { ...state };
  if (!nextState.ids.includes(contract.id)) {
    nextState.ids.push(contract.id);
  }
  if (!nextState.idsByMarketId[contract.marketId]) {
    nextState.idsByMarketId[contract.marketId] = [];
  }
  if (!nextState.idsByMarketId[contract.marketId].includes(contract.id)) {
    nextState.idsByMarketId[contract.marketId].push(contract.id);
  }
  nextState.byId[contract.id] = contract;
  return nextState;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { contracts } = action.payload;
      return contracts.reduce(reduce, { ...getInitialState() });
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { contracts } = action.payload;
      return contracts.reduce(reduce, { ...state });
    }
    case actionTypes.FETCHED_SINGLE: {
      const { contract } = action.payload;
      return reduce({ ...state }, contract);
    }
    case actionTypes.DELETED_SINGLE: {
      const { contractId } = action.payload;
      const nextState = { ...state };
      if (nextState.ids.includes(contractId)) {
        const idx = nextState.ids.indexOf(contractId);
        nextState.ids.splice(idx, 1);
      }
      if (nextState.byId[contractId]) {
        delete nextState.byId[contractId];
      }
      Object.keys(nextState.idsByMarketId).forEach((marketId) => {
        const ids = nextState.idsByMarketId[marketId];
        if (ids.includes(contractId)) {
          const idx = ids.indexOf(contractId);
          ids.splice(idx, 1);
        }
      });
      return nextState;
    }
    default:
      return state;
  }
}
