import { connect } from 'react-redux';
import { loginUser } from '../../../actions/Auth/authActions';
import LoginForm from './LoginForm';
import { trackLogin } from '../../../analytics/events/tracking';

function mapDispatchToProps(dispatch, ownProps) {
  return {
    login: (username, password, redirect, environment) => {
      dispatch(loginUser(username, password, ownProps.claim, redirect))
        .then(() => trackLogin(environment))
        .catch(() => {
          // Do nothing
        });
    },
  };
}

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    environment: state.config.environment,
    statusText: state.authReducer.loginReducer.statusText,
    redirect: ownProps.location.query.redirect || '/',
    subText: ownProps.subText,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
