import actionTypes from '../../actions/Permissions/actionTypes';

function getInitialState() {
  return {
    playerMarketSpaces: [],
    adminMarketSpaces: [],
    loaded: false,
  };
}

function reduce(state, permissions) {
  const nextState = { ...state };
  nextState.playerMarketSpaces = permissions.playerMarketSpaces;
  nextState.adminMarketSpaces = permissions.adminMarketSpaces;
  nextState.loaded = true;
  return nextState;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { permissions } = action.payload;
      return reduce(state, permissions);
    }
    default:
      return state;
  }
}
