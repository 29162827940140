import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { verifyReset, changePassword } from '@agora/agora-common/actions/passwordActions';

import { mailingListSignUp } from '../../../../utils/api';
import { loginUser, renewToken } from '../../../../actions/Auth/authActions';
import { notifyHttpError } from '../../../../actions/Common/actions';
import { updateProfile, resetSaveProfile, saveFailure } from '../../../../actions/Profile/actions';
import InviteNamePasswordForm from './InviteNamePasswordForm';

function mapStateToProps(state) {
  return {
    isChangingPassword: state.auth.isChangingPassword,
  };
}

function redirectToMarkets() {
  browserHistory.push('/Markets');
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    verifyReset() {
      const { email, key } = ownProps.location.query;
      dispatch(verifyReset(email, key));
    },
    createAccount(data) {
      const { email, key } = ownProps.location.query;

      dispatch(changePassword(email, key, data.password))
        .then(() => dispatch(loginUser(email, data.password, null, null)))
        .then(() => dispatch(updateProfile(data.name, data.realName, email, null, {
          inviteToOtherMarkets: data.inviteToOtherMarkets,
          gitHubUrl: data.gitHubUrl,
          googleScholarUrl: data.googleScholarUrl,
          linkedInUrl: data.linkedInUrl,
          other: data.other,
        })))
        .then(() => mailingListSignUp({
          optInEmail: data.mailingList,
        }))
        .then(() => dispatch(renewToken()))
        .then(() => {
          dispatch(resetSaveProfile());
          redirectToMarkets();
        })
        .catch((error) => {
          dispatch(saveFailure());
          dispatch(notifyHttpError(error, (statusCode) => {
            switch (statusCode) {
              case 409:
                return 'Another user is already using that Email address';
              default:
                return null; // Use default generic error message
            }
          }));
        });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteNamePasswordForm);
