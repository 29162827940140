import actionTypes from '../../../actions/Entities/AccountPnl/actionTypes';

function getInitialState() {
  return {
    latestByAccountId: {},
    historyByAccountId: {},
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_LATEST: {
      const { accountId, pnl } = action.payload;
      const nextState = { ...state };
      nextState.latestByAccountId[accountId] = pnl;
      return nextState;
    }
    case actionTypes.FETCHED_HISTORY: {
      const { accountId, pnlHistory } = action.payload;
      const nextState = { ...state };
      nextState.historyByAccountId[accountId] = pnlHistory;
      return nextState;
    }
    default:
      return state;
  }
}
