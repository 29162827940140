import { combineReducers } from 'redux';
import view from './viewReducer';
import marketPanel from './MarketPanel/reducer';

const rootReducer = combineReducers({
  view,
  marketPanel,
});

export default rootReducer;
