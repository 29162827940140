import actionTypes from '../../../../../../actions/Ui/Views/Admin/Market/Deletor/actionTypes';

const initialState = {
  isLoadingStates: false,
  isDeleting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_STATES:
      return {
        ...state,
        isLoadingStates: true,
      };
    case actionTypes.LOADED_STATES:
      return {
        ...state,
        isLoadingStates: false,
      };
    case actionTypes.DELETING:
      return {
        ...state,
        isDeleting: true,
      };
    case actionTypes.DELETED:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
