import React from 'react';
import { browserHistory, Link } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import FlatButton from '@agora/agora-common/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import { withStyles, withTheme } from '@material-ui/core/styles';
import * as colours from '../../../styles/colours';
import ContentHolder from '../Common/ContentHolder';
import FormSection from '../Common/FormSection';
import './login.css';

const styles = theme => ({
  container: {
    backgroundColor: colours.pageBackground,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    height: '100%',
  },
  login: {
    color: theme.palette.primary.main,
    fontSize: '13.5px',
    fontWeight: 500,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    const redirectTo = this.props.redirect;
    this.state = {
      username: '',
      password: '',
      redirect: redirectTo,
      snackbarOpen: !!this.props.statusText,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      browserHistory.push('/Markets');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.statusText && this.props.statusText !== prevProps.statusText) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ snackbarOpen: true });
    }
  }

  render() {
    return (
      <ContentHolder>
        <Grid container alignItems="center" justify="center" classes={{ container: this.props.classes.content }}>
          <Grid item xs={11} md={4}>
            <h1>{this.props.title}</h1>
            <form style={{ paddingTop: '1em' }}>
              <FormSection>
                <TextField
                  placeholder="Email"
                  fullWidth
                  value={this.state.username}
                  onChange={event => this.setState({ username: event.target.value })}
                />
              </FormSection>
              <FormSection>
                <TextField
                  placeholder="Password"
                  fullWidth
                  type="password"
                  value={this.state.password}
                  onChange={event => this.setState({ password: event.target.value })}
                />
              </FormSection>
              <div>
                <Link className={classNames(this.props.classes.login)} to="/forgot-password">
                  Don&apos;t remember your password?
                </Link>
              </div>
              <div style={{ paddingTop: '2em' }}>
                <FlatButton
                  type="submit"
                  disabled={this.props.isAuthenticating}
                  fullWidth
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.login(this.state.username, this.state.password, this.state.redirect, this.props.environment);
                  }}
                >
                  Continue
                </FlatButton>
              </div>
            </form>
          </Grid>
        </Grid>
        <Snackbar
          open={this.state.snackbarOpen}
          message={this.props.statusText}
          onClose={() => this.setState({ snackbarOpen: false })}
        />
      </ContentHolder>
    );
  }
}

LoginForm.propTypes = {
  environment: PropTypes.string.isRequired,
  statusText: PropTypes.string,
  login: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

LoginForm.defaultProps = {
  redirect: '/',
  statusText: '',
};

export default withStyles(styles)(withTheme(LoginForm));
