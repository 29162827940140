import { combineReducers } from 'redux';
import {
  snackbar, formConfig, theme, consentManager,
} from '@agora/agora-common/reducers';
import views from './Views/reducer';
import entities from './Entities/reducer';

const rootReducer = combineReducers({
  snackbar,
  consentManager,
  formConfig,
  theme,
  views,
  entities,
});

export default rootReducer;
