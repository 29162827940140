import { connect } from 'react-redux';
import { setConsentManagerMode, openConsentManagerDialog } from '@agora/agora-common/actions/consentManagerActions';
import {
  fetchProfile, updateProfile, deleteProfile, sendVerifyEmail,
} from '../../../actions/Profile/actions';
import Profile from './Profile';
import consentModes from '../../../consts/consentModes';

function getId(state) {
  return state.profile.id;
}

function getIsLoading(state) {
  return state.profile.isLoading;
}

function getIsSaving(state) {
  return state.profile.isSaving;
}

function getHasSaved(state) {
  return state.profile.hasSaved;
}

function getName(state) {
  return state.profile.name;
}

function getRealName(state) {
  return state.profile.realName;
}

function getEmail(state) {
  return state.profile.email;
}

function getPreferences(state) {
  return state.profile.preferences;
}

function getVerified(state) {
  return state.profile.verified;
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: () => dispatch(fetchProfile()),
    deleteUser: id => dispatch(deleteProfile(id)),
    save: (name, formRealName, email, notificationPreferences, socialPreferences) => dispatch(updateProfile(name, formRealName, email, notificationPreferences, socialPreferences)),
    openConsentManager: () => {
      dispatch(setConsentManagerMode(consentModes.EDIT));
      dispatch(openConsentManagerDialog());
    },
    sendVerifyEmail: () => dispatch(sendVerifyEmail()),
  };
}

function mapStateToProps(state) {
  return {
    email: getEmail(state),
    id: getId(state),
    isLoading: getIsLoading(state),
    isSaving: getIsSaving(state),
    hasSaved: getHasSaved(state),
    name: getName(state),
    preferences: getPreferences(state),
    realName: getRealName(state),
    verified: getVerified(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
