import { combineReducers } from 'redux';
import root from './rootReducer';
import stateControl from './StateControl/reducer';
import settlementControl from './SettlementControl/reducer';

const rootReducer = combineReducers({
  root,
  stateControl,
  settlementControl,
});

export default rootReducer;
