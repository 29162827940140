import actionTypes from '../../../../../../actions/Ui/Views/Admin/MarketGroup/Editor/actionTypes';

const initialState = {
  isUpdating: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATING:
      return {
        ...state,
        isUpdating: true,
      };
    case actionTypes.UPDATED:
      return {
        ...state,
        isUpdating: false,
      };
    default:
      return state;
  }
}
