import hiveAuthActionTypes from '@agora/agora-common/actions/authActionTypes';
import { clearAnalytics } from '@agora/agora-common/Analytics';
import {
  trackMarketCreated,
  trackLoggedOut,
  trackMarketSettled,
  trackContractCreated,
  trackPlayerAcceptInvite,
} from '../events/tracking';
import marketActions from '../../actions/Control/actionTypes';
import settleActions from '../../actions/Ui/Views/Control/ControlPanel/SettlementControl/actionTypes';
import playerActions from '../../actions/Ui/Views/Market/MarketPanel/ContractCreator/actionTypes';
import profileActions from '../../actions/Profile/actionTypes';

export default {
  [marketActions.CREATE_MARKET_SUCCESS]: (store, action) => {
    if (action.payload && action.payload.market) {
      trackMarketCreated(action.payload.market);
    }
  },
  [settleActions.SETTLED]: trackMarketSettled,
  [profileActions.SAVE_PROFILE_RESET]: trackPlayerAcceptInvite,
  [playerActions.CREATED]: trackContractCreated,
  [hiveAuthActionTypes.LOGOUT_USER]: () => {
    trackLoggedOut();
    clearAnalytics();
  },
};
