import actionTypes from '../../../../../../../actions/Ui/Views/Market/MarketPanel/MarketTable/MarketTableTotalRow/actionTypes';

const initialState = {
  isLoadingQuote: false,
  isPlacingOrder: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_QUOTE: {
      const newState = { ...state };
      newState.isLoadingQuote = true;
      return newState;
    }
    case actionTypes.LOADED_QUOTE: {
      const newState = { ...state };
      newState.isLoadingQuote = false;
      return newState;
    }
    case actionTypes.PLACING_ORDER: {
      const newState = { ...state };
      newState.isPlacingOrder = true;
      return newState;
    }
    case actionTypes.PLACED_ORDER: {
      const newState = { ...state };
      newState.isPlacingOrder = false;
      return newState;
    }
    default:
      return state;
  }
}
