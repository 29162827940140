import actionTypes from '../../../actions/Entities/Partition/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
  };
}

function reduce(state, partition) {
  const next = { ...state };
  if (!next.ids.includes(partition.id)) {
    next.ids.push(partition.id);
  }
  next.byId[partition.id] = partition;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { partitions } = action.payload;
      return partitions.reduce(reduce, getInitialState());
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { partitions } = action.payload;
      return partitions.reduce(reduce, state);
    }
    case actionTypes.FETCHED_SINGLE: {
      const { partition } = action.payload;
      return reduce(state, partition);
    }
    default:
      return state;
  }
}
