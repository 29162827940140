import React from 'react';
/* eslint max-len: 0 */

export default function HivemindLogoImage() {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 244 65"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="normal" transform="translate(-167.000000, -145.000000)">
          <g id="Stacked-Group" transform="translate(166.000000, 25.000000)">
            <g id="agora" transform="translate(0.000000, 120.000000)">
              <text id="Agora" fontFamily="JosefinSans, Josefin Sans" fontSize="25" fontWeight="normal" fill="#FFFFFF">
                <tspan x="160.475" y="64">AGORA</tspan>
              </text>
              <g id="icons/logo/icon_text_landscape">
                <g id="icons/logo/icon/main" fillRule="evenodd" strokeWidth="1">
                  <g id="Group" transform="translate(1.844618, 0.000000)">
                    <path d="M30.3949005,27.9694451 L30.3949005,17.7019503 C30.3810612,17.2918063 30.1675326,16.9157691 29.825703,16.699558 L21.0527174,11.5620633 C20.6966261,11.3698595 20.2704137,11.3698595 19.9143224,11.5620633 L11.1413368,16.699558 C10.7995072,16.9157691 10.5859786,17.2918063 10.5721393,17.7019503 L10.5721393,27.9694451 C10.5859786,28.3795891 10.7995072,28.7556263 11.1413368,28.9718374 L19.9143224,34.1093321 C20.2703338,34.3018644 20.696706,34.3018644 21.0527174,34.1093321 L29.825703,28.9718374 C30.1675326,28.7556263 30.3810612,28.3795891 30.3949005,27.9694451 L30.3949005,27.9694451 Z M25.3988155,25.0728124 C25.3845016,25.4666148 25.1776459,25.8268606 24.8479793,26.0321112 L21.0215033,28.2804678 C20.6809294,28.4643432 20.2732576,28.4643432 19.9326836,28.2804678 L16.1062077,26.0321112 C15.7765411,25.8268606 15.5696853,25.4666148 15.5553714,25.0728124 L15.5553714,20.5985829 C15.5696853,20.2047806 15.7765411,19.8445348 16.1062077,19.6392841 L19.9326836,17.3909276 C20.2732576,17.2070522 20.6809294,17.2070522 21.0215033,17.3909276 L24.8479793,19.6392841 C25.1776459,19.8445348 25.3845016,20.2047806 25.3988155,20.5985829 L25.3988155,25.0709388 L25.3988155,25.0728124 Z" id="Shape" fill="#808080" fillRule="nonzero" />
                    <path d="M17.8993837,0.434520217 C17.8993837,0.434520217 17.8993837,-0.244417622 17.3206968,0.0950512975 L0.585338448,10.0143331 C0.230404663,10.2402282 0.0104699224,10.6330879 0,11.0598974 L0,33.9604707 C0.0134019695,34.3811037 0.230142602,34.7676223 0.578686875,34.9924562 L17.3140452,44.9117381 C17.3140452,44.9117381 17.8927321,45.2579964 17.8927321,44.5722692 L17.8927321,39.778968 C17.8793301,39.358335 17.6625895,38.9718165 17.3140452,38.7469825 L5.78021718,31.9100785 C5.4316729,31.6852445 5.21493227,31.298726 5.2015303,30.8780929 L5.2015303,14.1219071 C5.21709974,13.7037095 5.43357415,13.3202055 5.78021718,13.0967109 L17.3140452,6.2530175 C17.6625895,6.02818352 17.8793301,5.641665 17.8927321,5.22103199 L17.8993837,0.434520217 Z M40.3883529,34.9924562 C40.7368972,34.7676223 40.9536378,34.3811037 40.9670398,33.9604707 L40.9670398,11.0598974 C40.9536378,10.6392644 40.7368972,10.2527459 40.3883529,10.0279119 L23.6529946,0.0950512975 C23.6529946,0.0950512975 23.0743077,-0.251207001 23.0743077,0.434520217 L23.0743077,5.22103199 C23.0877097,5.641665 23.3044503,6.02818352 23.6529946,6.2530175 L35.1934742,13.0967109 C35.5420185,13.3215449 35.7587591,13.7080634 35.7721611,14.1286964 L35.7721611,30.8780929 C35.7587591,31.298726 35.5420185,31.6852445 35.1934742,31.9100785 L23.6529946,38.7469825 C23.3044503,38.9718165 23.0877097,39.358335 23.0743077,39.778968 L23.0743077,44.5654798 C23.0743077,44.5654798 23.0743077,45.2444176 23.6529946,44.9049487 L40.3883529,34.9924562 Z" id="Shape" fill="#FFC34D" fillRule="nonzero" />
                  </g>
                </g>
                <text id="HIVEMIND" fontFamily="JosefinSans-Bold, Josefin Sans" fontSize="33.3333333" fontWeight="bold" letterSpacing="3.64583333">
                  <tspan x="52.0833333" y="33.9176471" fill="#FFC34D">HIVE</tspan>
                  <tspan x="144.2" y="33.9176471" fill="#999999">MIND</tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
