/* eslint max-len: 0 */
export const defaultInstructions = `
# Instructions for Trading

### Contracts

The *market* is based on individual outcomes. You can place bets and win credits in the market through the trading of *contracts* which are custom bets defined by you. A contract is a collection of *weights* for one or more of the outcomes for the market. Each unit of the contract that you own will pay out a number of credits equal to the weight of the event that occurs.

You can create contracts within the application by clicking on outcomes to select or deselect them, or by dragging to select or select groups of outcomes at once. Contracts created this way will have a weight of 1 on all selected outcomes and 0 for other outcomes, meaning each unit of these contracts will pay out 1.0 credit if the outcome that the market is settled at is one of the selected ones.

### Getting Quotes and Trading

Once you have defined a contract you can get a price quote for the quantity you wish to buy. When getting a quote you either specify the number you want to buy or specify what you want your final holding of that contract to be. You can then choose too trade at the quoted amount, which will create an order for the specified number of contracts.

If you wish to sell contracts you already own you can get a price quote in the same way.

The price quoted might not be the price that you trade at, depending on whether other players have placed bets between getting the quote and placing the order. If the price moves against you more than 1% from the quoted amount, your order will be rejected. More controlled orders can be placed via the API.
 
Once your order has been accepted and executed, you will see your credits and contracts update accordingly.

### Shorting
You cannot “short” contracts – that is sell contracts you haven’t previously bought. However, because the outcome space covers all possible outcomes and the prices sum to 1.00, if you believe any outcomes are overpriced it follows that other outcomes must be underpriced. You can take advantage of the mispricing by buying the underpriced outcomes.
`;

export const defaultLiquidityFactor = 500.0;
export const defaultSpreadFactor = 0.0001;
export const defaultSpreadConstant = 0.0001;