import { combineReducers } from 'redux';
import claims from './Claims/claimsReducer';
import actionTypes from '../../actions/Auth/authActionsTypes';

function getVerifyEmailInitialState() {
  return {
    showSuccess: false,
    showError: false,
    resultText: '',
  };
}

function getLoginInitialState() {
  return {
    statusText: '',
  };
}

function verifyEmailReducer(state = getVerifyEmailInitialState(), action) {
  switch (action.type) {
    case actionTypes.VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        showSuccess: true,
        showError: false,
      };
    }
    case actionTypes.VERIFY_EMAIL_FAILURE: {
      return {
        ...state,
        showSuccess: false,
        showError: true,
      };
    }
    default:
      return state;
  }
}

function loginReducer(state = getLoginInitialState(), action) {
  switch (action.type) {
    case actionTypes.LOGIN_USER_REQUEST: {
      return {
        ...state,
        statusText: '',
      };
    }
    case actionTypes.LOGIN_USER_FAILURE: {
      return {
        ...state,
        statusText: action.payload.statusText,
      };
    }
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  claims,
  verifyEmailReducer,
  loginReducer,
});

export default rootReducer;
