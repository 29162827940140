import actionTypes from '../../../actions/Entities/MarketGroup/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
  };
}

function reduce(state, marketGroup) {
  const next = { ...state };
  if (!next.ids.includes(marketGroup.id)) {
    next.ids.push(marketGroup.id);
  }
  next.byId[marketGroup.id] = marketGroup;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { marketGroups } = action.payload;
      return marketGroups.reduce(reduce, getInitialState());
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { marketGroups } = action.payload;
      return marketGroups.reduce(reduce, state);
    }
    case actionTypes.FETCHED_SINGLE: {
      const { marketGroup } = action.payload;
      return reduce(state, marketGroup);
    }
    case actionTypes.DELETED_SINGLE: {
      const { marketGroupId } = action.payload;
      const nextState = { ...state };
      if (nextState.ids.includes(marketGroupId)) {
        const idx = nextState.ids.indexOf(marketGroupId);
        nextState.ids.splice(idx, 1);
      }
      if (nextState.byId[marketGroupId]) {
        delete nextState.byId[marketGroupId];
      }
      return nextState;
    }
    default:
      return state;
  }
}
