import claimsActionTypes from '../../../actions/Auth/Claims/claimsActionTypes';

function getInitialState() {
  return {
    byName: {},
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case claimsActionTypes.SET_HAS_CLAIM: {
      const newState = { ...state };
      newState.byName[action.payload.name] = action.payload.hasClaim || false;
      return newState;
    }
    case claimsActionTypes.SET_ORG_ROLE: {
      const newState = { ...state };
      newState.byName[action.payload.role] = action.payload.organisations;
      return newState;
    }
    default:
      return state;
  }
}
