import Control from './Control';
import Market from './Market';
import Accounts from './Accounts';
import Admin from './Admin';
import Profile from './Profile';

export default {
  Control,
  Market,
  Accounts,
  Admin,
  Profile,
};
