import actionTypes from '../../../actions/Entities/Invitations/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
  };
}

function reduce(state, marketSpace) {
  const next = { ...state };
  if (!next.ids.includes(marketSpace.id)) {
    next.ids.push(marketSpace.id);
  }
  next.byId[marketSpace.id] = marketSpace;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { invitations } = action.payload;
      return invitations.reduce(reduce, getInitialState());
    }
    default:
      return state;
  }
}
