import Loadable from 'react-loadable';
import { ModuleLoadingPage } from '@agora/agora-common/Loading';

const PublicPrices = Loadable({
  loader: () => import('./PublicPricesPageContainer'),
  timeout: 60000,
  loading: ModuleLoadingPage,

});

export default PublicPrices;
