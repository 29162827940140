import actionTypes from '../../../actions/Entities/MarketState/actionTypes';

function getInitialState() {
  return {
    marketIds: [],
    byMarketId: {},
  };
}

function reduce(state, marketState) {
  const next = { ...state };
  if (!next.marketIds.includes(marketState.marketId)) {
    next.marketIds.push(marketState.marketId);
  }
  next.byMarketId[marketState.marketId] = marketState;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { marketStates } = action.payload;
      return marketStates.reduce(reduce, getInitialState());
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { marketStates } = action.payload;
      return marketStates.reduce(reduce, state);
    }
    case actionTypes.FETCHED_SINGLE: {
      const { marketState } = action.payload;
      return reduce(state, marketState);
    }
    default:
      return state;
  }
}
