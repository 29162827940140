import { combineReducers } from 'redux';
import root from './rootReducer';
import marketTableRow from './MarketTableRow/reducer';
import marketTableTotalRow from './MarketTableTotalRow/reducer';

const rootReducer = combineReducers({
  root,
  marketTableRow,
  marketTableTotalRow,
});

export default rootReducer;
