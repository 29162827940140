import actionTypes from '../../../../actions/Ui/Views/Leaderboard/actionTypes';

const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.INVITE_PLAYERS_REQUEST:
      return {
        ...state,
        isUpdatingPlayers: true,
      };
    case actionTypes.INVITE_PLAYERS_SUCCESS:
      return {
        ...state,
        isUpdatingPlayers: false,
      };
    case actionTypes.INVITE_PLAYERS_FAILURE:
      return {
        ...state,
        isUpdatingPlayers: false,
      };
    case actionTypes.REMOVING_PLAYER:
      return {
        ...state,
        isUpdatingPlayers: true,
      };
    case actionTypes.REMOVED_PLAYER:
      return {
        ...state,
        isUpdatingPlayers: false,
      };
    default:
      return state;
  }
}
