export function getHttpErrorMessage(error, customError) {
  let message = null;

  // Try to bubble up the error response from the server
  if (error && error.response) {
    if (error.response.statusText) {
      message = error.response.statusText;
    } else if (customError) {
      // A custom error handler is supplied if the caller wants to handle
      // specific types of errors that might be expected, e.g. duplicate object
      const customMessage = customError(error.response.status);
      if (customMessage) {
        message = customMessage;
      }
    }
  }

  if (!message) {
    if (error.response) {
      // Fall into this for http error responses that we aren't expecting
      message = `An unexpected error occurred (${error.response.status})`;
    } else if (error.message) {
      // Can occur for other types of error, e.g. no response
      message = `An unexpected error occurred (${error.message})`;
    } else {
      // We have no error info
      message = 'An unexpected error occurred';
    }
  }

  return message;
}
