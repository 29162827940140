export default {
  FETCH_PROFILE_REQUEST: 'FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_PROFILEVERIFIED_SUCCESS: 'FETCH_PROFILEVERIFIED_SUCCESS',
  SAVE_PROFILE_RESET: 'SAVE_PROFILE_RESET',
  SAVE_PROFILE_REQUEST: 'SAVE_PROFILE_REQUEST',
  SAVE_PROFILE_SUCCESS: 'SAVE_PROFILE_SUCCESS',
  SAVE_PROFILE_FAILURE: 'SAVE_PROFILE_FAILURE',
  DELETE_PROFILE_REQUEST: 'DELETE_PROFILE_REQUEST',
  DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS',
};
