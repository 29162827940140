import { combineReducers } from 'redux';
import creator from './Creator/reducer';
import editor from './Editor/reducer';
import deletor from './Deletor/reducer';

const rootReducer = combineReducers({
  creator,
  editor,
  deletor,
});

export default rootReducer;
