import jwtDecode from 'jwt-decode';
import { trackEvent, createIdentity } from '@agora/agora-common/Analytics';
import { isValidToken } from '@agora/agora-common/utils';
import {
  AccountAnalytics, CreateMarketEventAnalytics,
} from '../consts/eventTypes';

function getUserId() {
  const token = localStorage.getItem('token');

  if (isValidToken(token)) {
    const decodedToken = jwtDecode(token);
    const { id } = decodedToken;
    return id;
  }

  return null;
}

export const trackLoggedOut = () => trackEvent({
  event: AccountAnalytics.LOGOUT,
  properties: {
    category: 'Account',
    label: 'Logout',
    path: '/logout',
    url: `${window.location.origin}/logout`,
  },
});

export const trackPlayerAcceptInvite = () => trackEvent({
  event: AccountAnalytics.PLAYER_CREATED,
  properties: {
    category: 'Account',
    label: 'Accept Invite',
    path: '/account/invite',
    url: `${window.location.origin}/account/invite`,
  },
});

export const trackLogin = (environment) => {
  createIdentity({
    userId: `${environment}-${getUserId()}`,
  });
  trackEvent({
    event: AccountAnalytics.LOGIN,
    properties: {
      category: 'Account',
      label: 'Login',
      path: '/login',
      url: `${window.location.origin}/login`,
    },
  });
};

export const trackMarketCreated = createdMarket => trackEvent({
  event: CreateMarketEventAnalytics.CREATED,
  properties: {
    label: 'Market Created',
    category: 'Create Market',
    path: '/markets/create',
    url: `${window.location.origin}/markets/create`,
    market: {
      leaderboard: createdMarket.leaderboard,
      liquidity: createdMarket.market.liquidity,
      liquidityChanged: createdMarket.market.liquidityChanged,
      spreadFactor: createdMarket.market.spreadFactor,
      spreadConstant: createdMarket.market.spreadConstant,
      startingCredits: createdMarket.market.startingCredits,
      variable: createdMarket.variable.outcomeType,
      playersInvited: createdMarket.invitation.players.length,
    },
  },
});

export const trackMarketOpened = () => trackEvent({
  event: CreateMarketEventAnalytics.OPEN,
  properties: {
    label: 'Open Market',
    category: 'Market Management',
    path: '/market/X/open',
    url: `${window.location.origin}/market/X/open`,
  },
});

export const trackMarketClosed = () => trackEvent({
  event: CreateMarketEventAnalytics.CLOSED,
  properties: {
    label: 'Close Market',
    category: 'Market Management',
    path: '/markets/X/close',
    url: `${window.location.origin}/markets/X/close`,
  },
});

export const trackMarketSettled = () => trackEvent({
  event: CreateMarketEventAnalytics.SETTLED,
  properties: {
    label: 'Settle Market',
    category: 'Market Management',
    path: '/markets/X/settle',
    url: `${window.location.origin}/markets/X/settle`,
  },
});

export const trackContractCreated = () => trackEvent({
  event: CreateMarketEventAnalytics.CREATED_CONTRACT,
  properties: {
    label: 'Create Contract',
    category: 'Player Market',
    path: '/markets/X/contract-create',
    url: `${window.location.origin}/markets/X/contract-create`,
  },
});

export const trackMobileTabSelect = name => trackEvent({
  event: `Tapped ${name} Tab`,
  properties: {
    label: `Mobile ${name} Tab`,
    category: 'Create Market',
    path: '/market/create',
    url: `${window.location.origin}/market/create`,
  },
});
