export default {
  CREATE_MARKET_REQUEST: 'CREATE_MARKET_REQUEST',
  CREATE_MARKET_FAILURE: 'CREATE_MARKET_FAILURE',
  CREATE_MARKET_SUCCESS: 'CREATE_MARKET_SUCCESS',
  CREATE_MARKET_RESET: 'CREATE_MARKET_RESET',
  CREATE_MARKET_VARIABLE: 'CREATE_MARKET_VARIABLE',
  INVITE_PLAYERS_REQUEST: 'INVITE_PLAYERS_REQUEST',
  INVITE_PLAYERS_SUCCESS: 'INVITE_PLAYERS_SUCCESS',
  UPDATE_MARKET_INITIALISE: 'UPDATE_MARKET_INITIALISE',
  UPDATE_MARKET_REQUEST: 'UPDATE_MARKET_REQUEST',
  UPDATE_MARKET_SUCCESS: 'UPDATE_MARKET_SUCCESS',
  UPDATE_MARKET_FAILURE: 'UPDATE_MARKET_FAILURE',
};
