export default function middlewareFactory(config) {
  const actionLookup = config;
  return function storeHandler(store) {
    return function nextHandler(nextDispatch) {
      return function actionHandler(action) {
        if (action && action.type && actionLookup && actionLookup[action.type]) {
          try {
            actionLookup[action.type](store, action);
          } catch (err) {
            /* eslint-disable no-console */
            console.warn(`Analytics middleware - failed to process ${action.type}`);
          }
        }
        return nextDispatch(action);
      };
    };
  };
}
