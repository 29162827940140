import { combineReducers } from 'redux';
import root from './rootReducer';
import marketTable from './MarketTable/reducer';
import marketSettlementTable from './MarketSettlementTable/reducer';
import contractCreator from './ContractCreator/reducer';

const rootReducer = combineReducers({
  root,
  marketTable,
  marketSettlementTable,
  contractCreator,
});

export default rootReducer;
