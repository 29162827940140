export const AccountAnalytics = {
  LOGIN: 'Logged In',
  LOGOUT: 'Logged Out',
  CREATED: 'Account Created',
  PLAYER_CREATED: 'Player Account Created',
};
export const CreateMarketEventAnalytics = {
  CREATED: 'Created Market',
  OPEN: 'Opened Market',
  CLOSED: 'Closed Market',
  SETTLED: 'Settled Market',
  CREATED_CONTRACT: 'Created A Contract',
};
