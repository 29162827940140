/* eslint no-param-reassign: 0 */
import actionTypes from '../../../../../actions/Ui/Entities/Market/ReduxHistogram/actionTypes';

const getInitialState = () => ({});

const getInitialHistogramState = id => ({
  id,
  market: null,
  variable: null,
  selectable: false,
  values: {},
  valueRange: { min: 0, max: 1 },
  canvasSize: null,
  chartSize: null,
  scalingFactor: null,
  xAxisSize: null,
  yAxisSize: null,
  yAxisLabel: null,
  xAxisLabelSize: null,
  yAxisLabelSize: null,
  xAxisTickLabelsSize: null,
  yAxisTickLabelsSize: null,
  tooltip: null,
  bodySize: null,
  eventPositions: null,
  selected: {},
  selectionBatch: null,
  yAxisValues: null,
});

const validActionTypes = Object.values(actionTypes);

function reduce(histogramId, state, action) {
  switch (action.type) {
    case actionTypes.SET_MARKET: {
      const { market } = action.payload;
      return {
        ...state,
        market,
      };
    }
    case actionTypes.SET_VARIABLE: {
      const { variable } = action.payload;
      return {
        ...state,
        variable,
      };
    }
    case actionTypes.SET_SELECTABLE: {
      const { selectable } = action.payload;
      return {
        ...state,
        selectable: !!selectable,
      };
    }
    case actionTypes.SET_VALUES: {
      const { values } = action.payload;
      return {
        ...state,
        values,
      };
    }
    case actionTypes.SET_VALUE_RANGE: {
      const { range } = action.payload;
      return {
        ...state,
        valueRange: range,
      };
    }
    case actionTypes.SET_CANVAS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        canvasSize: size,
      };
    }
    case actionTypes.SET_CHART_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        chartSize: size,
      };
    }
    case actionTypes.SET_SCALING_FACTOR: {
      const { factor } = action.payload;
      return {
        ...state,
        scalingFactor: factor,
      };
    }
    case actionTypes.SET_X_AXIS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        xAxisSize: size,
      };
    }
    case actionTypes.SET_Y_AXIS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        yAxisSize: size,
      };
    }
    case actionTypes.SET_X_AXIS_LABEL_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        xAxisLabelSize: size,
      };
    }
    case actionTypes.SET_Y_AXIS_LABEL_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        yAxisLabelSize: size,
      };
    }
    case actionTypes.SET_X_AXIS_TICK_LABELS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        xAxisTickLabelsSize: size,
      };
    }
    case actionTypes.SET_Y_AXIS_TICK_LABELS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        yAxisTickLabelsSize: size,
      };
    }
    case actionTypes.SET_BODY_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        bodySize: size,
      };
    }
    case actionTypes.SET_EVENT_POSITIONS: {
      const { positions } = action.payload;
      return {
        ...state,
        eventPositions: positions,
      };
    }
    case actionTypes.SET_Y_AXIS_VALUES: {
      const { values } = action.payload;
      return {
        ...state,
        yAxisValues: values,
      };
    }
    case actionTypes.SET_TOOLTIP: {
      const { tooltip } = action.payload;
      return {
        ...state,
        tooltip,
      };
    }
    case actionTypes.SET_Y_AXIS_LABEL: {
      const { yAxisLabel } = action.payload;
      return {
        ...state,
        yAxisLabel,
      };
    }
    case actionTypes.CLEAR_SELECTED: {
      return {
        ...state,
        selected: {},
        selectionBatch: null,
      };
    }
    case actionTypes.START_SELECTION_BATCH: {
      const { selecting } = action.payload;
      return {
        ...state,
        selectionBatch: {
          selecting,
          batch: { eventIds: [] },
        },
      };
    }
    case actionTypes.UPDATE_SELECTION_BATCH: {
      const { batch } = action.payload;
      return {
        ...state,
        selectionBatch:
          state.selectionBatch !== null
            ? {
              selecting: state.selectionBatch.selecting,
              batch,
            }
            : null,
      };
    }
    case actionTypes.COMMIT_SELECTION_BATCH: {
      const nextState = { ...state };
      if (state.selectionBatch) {
        const newSelected = nextState.selected;
        state.selectionBatch.batch.eventIds.forEach((eventId) => {
          newSelected[eventId] = state.selectionBatch.selecting;
        });
        return {
          ...nextState,
          selected: newSelected,
          selectionBatch: null,
        };
      }
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default function (state = getInitialState(), action) {
  if (action.type === actionTypes.DESTROY) {
    const { histogramId } = action.payload;
    const nextState = { ...state };
    delete nextState[histogramId];
    return nextState;
  }

  if (validActionTypes.includes(action.type)) {
    const { histogramId } = action.payload;
    const histogramState = state[histogramId] || getInitialHistogramState(histogramId);
    const nextState = { ...state };
    nextState[histogramId] = reduce(histogramId, histogramState, action);
    return nextState;
  }

  return state;
}
