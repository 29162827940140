import snackbarActionTypes from '@agora/agora-common/actions/snackbarActionTypes';
import { getHttpErrorMessage } from '../../utils/http';

function handleError(error, customError) {
  const message = getHttpErrorMessage(error, customError);

  return {
    type: snackbarActionTypes.OPEN,
    payload: {
      message,
      success: false,
    },
  };
}

export function notifyHttpError(error, customError) {
  return handleError(error, customError);
}
