import actionTypes from '../../../actions/Entities/Variable/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
  };
}

function reduce(state, variable) {
  const next = { ...state };
  if (!next.ids.includes(variable.id)) {
    next.ids.push(variable.id);
  }
  next.byId[variable.id] = variable;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { variables } = action.payload;
      return variables.reduce(reduce, getInitialState());
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { variables } = action.payload;
      return variables.reduce(reduce, state);
    }
    case actionTypes.FETCHED_SINGLE: {
      const { variable } = action.payload;
      return reduce(state, variable);
    }
    case actionTypes.DELETED_SINGLE: {
      const { variableId } = action.payload;
      const nextState = { ...state };
      if (nextState.ids.includes(variableId)) {
        const idx = nextState.ids.indexOf(variableId);
        nextState.ids.splice(idx, 1);
      }
      if (nextState.byId[variableId]) {
        delete nextState.byId[variableId];
      }
      return nextState;
    }
    default:
      return state;
  }
}
