import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Snackbar from '@agora/agora-common/Snackbar';

import * as colours from '../../../../styles/colours';
import './content-holder.css';
import AgoraHeader from '../AgoraHeader';


const styles = () => ({
  container: {
    backgroundColor: colours.pageBackground,
    height: '100vh',
  },
  content: {
    height: '100%',
  },
});

function ContentHolder({
  children,
  classes,
  navButtons,
}) {
  return (
    <Grid
      container
      classes={{ container: classes.container }}
      direction="column"
      wrap="nowrap"
    >
      <AgoraHeader>
        {navButtons}
      </AgoraHeader>
      <Grid
        container
        classes={{ container: classes.content }}
      >
        {children}
        <Snackbar />
      </Grid>
    </Grid>
  );
}

ContentHolder.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  navButtons: PropTypes.node,
};

ContentHolder.defaultProps = {
  navButtons: [],
};

export default withStyles(styles)(ContentHolder);