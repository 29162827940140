import actionTypes from '../../../actions/Entities/Leaderboard/actionTypes';

function getInitialState() {
  return {
    marketSpaceId: [],
    idsByMarketSpaceId: {},
  };
}

function reduce(state, leaderboard) {
  const next = { ...state };
  if (!next.marketSpaceId.includes(leaderboard.marketSpaceId)) {
    next.marketSpaceId.push(leaderboard.marketSpaceId);
  }
  next.idsByMarketSpaceId[leaderboard.marketSpaceId] = leaderboard;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_SINGLE: {
      const { leaderboard } = action.payload;
      return reduce(state, leaderboard);
    }
    default:
      return state;
  }
}
