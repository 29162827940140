import jwtDecode from 'jwt-decode';
import { isValidToken } from '@agora/agora-common/utils/authUtils';

import claimTypes from '../../actions/Auth/Claims/claimTypes';

function getUserToken() {
  const token = localStorage.getItem('token');

  if (!isValidToken(token)) {
    throw new Error('Invalid token');
  }

  return jwtDecode(token);
}

function getMemberships() {
  const token = getUserToken();
  let memberships = token[claimTypes.CLAIM_MEMBERSHIPS];

  if (!memberships) {
    // User has no memberships which usually only happens when they are created via API.
    return [];
  } if (typeof memberships === 'string') {
    // Single membership needs to be coerce into an array
    memberships = [memberships];
  }

  return memberships
    .map((x) => {
      const vals = x.split(':');
      return {
        orgId: vals[0],
        role: vals[1],
      };
    });
}

export function getOperatorMemberships() {
  const memberships = getMemberships();
  return memberships
    .filter(o => o.role === claimTypes.ROLE_ORGADMIN
            || o.role === claimTypes.ROLE_OPERATOR);
}

export function getUserEmail() {
  const token = getUserToken();
  return token[claimTypes.CLAIM_EMAIL];
}

export function getUserId() {
  const token = getUserToken();
  return token[claimTypes.CLAIM_ID];
}