import { applyMiddleware, compose, createStore } from 'redux';
import createRavenMiddleware from 'raven-for-redux';
import Raven from 'raven-js';
import reduxThunkMiddleware from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createLogger } from 'redux-logger';
import analyticsMiddleware from './analytics/middleware';

import reducers from './reducers';

Raven.config('https://c4b0ad42e07a4305b658310941b49efb@sentry.io/1352268').install();

// eslint-disable-next-line no-underscore-dangle,no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loggerMiddleware = createLogger();
const debounceMiddleware = createDebounce();

const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      reduxThunkMiddleware,
      loggerMiddleware,
      debounceMiddleware,
      analyticsMiddleware,
      createRavenMiddleware(Raven),
    ),
  ),
);

export default store;