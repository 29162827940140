import actionTypes from '../../../actions/Entities/ContractValuation/actionTypes';

function getInitialState() {
  return {
    byAccountId: {},
    byMarketId: {},
    byContractId: {},
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_FOR_ACCOUNT: {
      const { contractValuations } = action.payload;
      const nextState = { ...state };
      nextState.byAccountId[contractValuations.accountId] = contractValuations;
      contractValuations.valuations.forEach((valuation) => {
        nextState.byContractId[valuation.contractId] = valuation;
      });
      return nextState;
    }
    case actionTypes.FETCHED_FOR_MARKET: {
      const { contractValuations } = action.payload;
      const nextState = { ...state };
      nextState.byMarketId[contractValuations.marketId] = contractValuations;
      contractValuations.valuations.forEach((valuation) => {
        nextState.byContractId[valuation.contractId] = valuation;
      });
      return nextState;
    }
    default:
      return state;
  }
}
