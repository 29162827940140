import { authFetch as hivemindAuthFetch } from '@agora/agora-common/utils';
import store from '../../store';

function authFetch(url, options) {
  const state = store.getState();
  return hivemindAuthFetch(`${state.config.baseUrl}/${url}`, options);
}

function get(url) {
  return authFetch(url);
}

function post(url, data) {
  const options = {
    method: 'POST',
    body: data ? JSON.stringify(data) : undefined,
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  };
  return authFetch(url, options);
}

function put(url, data) {
  const options = {
    method: 'PUT',
    body: data ? JSON.stringify(data) : undefined,
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  };
  return authFetch(url, options);
}

function patch(url, data) {
  const options = {
    method: 'PATCH',
    body: data ? JSON.stringify(data) : undefined,
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  };
  return authFetch(url, options);
}
function del(url, data) {
  const options = {
    method: 'DELETE',
    body: data ? JSON.stringify(data) : undefined,
    headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  };
  return authFetch(url, options);
}

export function renewToken() {
  return get('ui/auth/renew');
}

export function getVerifyEmail() {
  return get('ui/auth/email/verify');
}

export function sendVerifyEmail() {
  return post('ui/auth/email/verify/send');
}

export function getProfile() {
  return get('ui/manage/users/me');
}

export function getProfilePreferences() {
  return get('ui/manage/users/me/preferences');
}

export function updateProfilePreferences(definition) {
  return put('ui/manage/users/me/preferences', definition);
}

export function mailingListSignUp(definition) {
  return post('ui/manage/users/me/mailinglist', definition);
}

export function updateProfile(definition) {
  return patch('ui/manage/users/me', definition);
}

export function deleteProfile(userId) {
  return del(`ui/manage/users/${userId}`);
}

export function getPlayerEntities(entityTypes) {
  const queryStr = entityTypes.map(type => `${type}=true`).join('&');
  return get(`ui/entities/player?${queryStr}`);
}

export function getManagementEntities(entityTypes) {
  const queryStr = entityTypes.map(type => `${type}=true`).join('&');
  return get(`ui/entities/manage?${queryStr}`);
}

export function getAdminEntities(entityTypes) {
  const queryStr = entityTypes.map(type => `${type}=true`).join('&');
  return get(`ui/entities/admin?${queryStr}`);
}

export function getOrganisationPlayerEntities(organisationId, entityTypes) {
  const queryStr = entityTypes.map(type => `${type}=true`).join('&');
  return get(`ui/entities/${organisationId}/player?${queryStr}`);
}

export function getMarketPlayerEntities(organisationId, marketId, entityTypes) {
  const queryStr = entityTypes.map(type => `${type}=true`).join('&');
  return get(`ui/entities/${organisationId}/player/${marketId}?${queryStr}`);
}

export function getMarketAdminEntities(organisationId, marketId, entityTypes) {
  const queryStr = entityTypes.map(type => `${type}=true`).join('&');
  return get(`ui/entities/${organisationId}/admin/${marketId}?${queryStr}`);
}

export function getPermissions() {
  return get('ui/play/market-spaces/permissions/me');
}

export function getOrganisations() {
  return get('ui/manage/orgs');
}

export function getOrganisation(organisationId) {
  return get(`ui/manage/orgs/${organisationId}`);
}

export function getPartitions() {
  return get('ui/manage/partitions');
}

export function getPartition(partitionId) {
  return get(`ui/manage/partitions/${partitionId}`);
}

export function createPartition(definition) {
  return post('ui/manage/partitions', definition);
}

export function getUser() {
  return get('ui/manage/users/me');
}

export function getAccounts() {
  return get('ui/play/accounts');
}

export function getAccount(organisationId, accountId) {
  return get(`ui/play/${organisationId}/accounts/${accountId}`);
}

export function getAccountForMarketSpace(organisationId, marketSpaceId) {
  return get(`ui/play/${organisationId}/accounts/market-space/${marketSpaceId}`);
}

export function getAccountValuation(organisationId, accountId) {
  return get(`ui/play/${organisationId}/accounts/${accountId}/valuation`);
}

export function getAccountValuationHistory(organisationId, accountId) {
  return get(`ui/play/${organisationId}/accounts/${accountId}/valuation/history`);
}

export function getAccountContractValuations(organisationId, accountId) {
  return get(`ui/play/${organisationId}/accounts/${accountId}/contract-valuations`);
}

export function getMarketContractValuations(organisationId, accountId, marketId) {
  return get(`ui/play/${organisationId}/accounts/${accountId}/contract-valuations/${marketId}`);
}

export function getContract(organisationId, contractId) {
  return get(`ui/play/${organisationId}/contracts/${contractId}`);
}

export function createContract(organisationId, definition) {
  return post(`ui/play/${organisationId}/contracts`, definition);
}

export function deleteContract(organisationId, contractId) {
  return del(`ui/play/${organisationId}/contracts/${contractId}`);
}

export function getMarkets() {
  return get('ui/manage/markets');
}

export function getMarket(organisationId, marketId) {
  return get(`ui/manage/${organisationId}/markets/${marketId}`);
}

export function createMarket(organisationId, definition) {
  return post(`ui/manage/${organisationId}/markets`, definition);
}

export function updateMarket(organisationId, marketId, definition) {
  return patch(`ui/manage/${organisationId}/markets/${marketId}`, definition);
}

export function deleteMarket(organisationId, marketId) {
  return del(`ui/manage/${organisationId}/markets/${marketId}`);
}

export function getMarketGroups() {
  return get('ui/manage/market-groups');
}

export function getMarketGroup(organisationId, marketGroupId) {
  return get(`ui/manage/${organisationId}/market-groups/${marketGroupId}`);
}

export function createMarketGroup(organisationId, definition) {
  return post(`ui/manage/${organisationId}/market-groups`, definition);
}

export function updateMarketGroup(organisationId, marketGroupId, definition) {
  return patch(`ui/manage/${organisationId}/market-groups/${marketGroupId}`, definition);
}

export function deleteMarketGroup(organisationId, marketGroupId) {
  return del(`ui/manage/${organisationId}/market-groups/${marketGroupId}`);
}

export function getMarketSpaces() {
  return get('ui/manage/market-spaces');
}

export function getMarketSpace(organisationId, marketSpaceId) {
  return get(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}`);
}

export function createMarketSpace(organisationId, definition) {
  return post(`ui/manage/${organisationId}/market-spaces`, definition);
}

export function updateMarketSpace(organisationId, marketSpaceId, definition) {
  return patch(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}`, definition);
}

export function deleteMarketSpace(organisationId, marketSpaceId) {
  return del(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}`);
}

export function invitePlayers(organisationId, marketSpaceId, definition) {
  return post(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}/player`, definition);
}

export function removePlayer(organisationId, marketSpaceId, definition) {
  return del(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}/player/id`, definition);
}

export function getInvitations() {
  return get('ui/manage/market-spaces/invitation');
}

export function acceptInvitation(organisationId, marketSpaceId) {
  return post(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}/invitation`);
}

export function declineInvitation(organisationId, marketSpaceId) {
  return del(`ui/manage/${organisationId}/market-spaces/${marketSpaceId}/invitation`);
}

export function getMarketStates() {
  return get('ui/play/markets/states');
}

export function getMarketState(organisationId, marketId) {
  return get(`ui/play/${organisationId}/markets/${marketId}/state`);
}

export function getMarketSettlementSummary(organisationId, marketId) {
  return get(`ui/play/${organisationId}/markets/${marketId}/settlement-summary/me`);
}

export function openMarketForRealTimeOrders(organisationId, marketId) {
  return post(`ui/play/${organisationId}/markets/${marketId}/real-time/open`);
}

export function closeMarketForRealTimeOrders(organisationId, marketId) {
  return post(`ui/play/${organisationId}/markets/${marketId}/real-time/close`);
}

export function getVariables() {
  return get('ui/play/variables');
}

export function getVariable(organisationId, variableId) {
  return get(`ui/manage/${organisationId}/variables/${variableId}`);
}

export function createContinuousVariable(organisationId, definition) {
  return post(`ui/manage/${organisationId}/variables/continuous`, definition);
}

export function createCategoricalVariable(organisationId, definition) {
  return post(`ui/manage/${organisationId}/variables/categorical`, definition);
}

export function updateVariable(organisationId, variableId, definition) {
  return patch(`ui/manage/${organisationId}/variables/${variableId}`, definition);
}

export function deleteVariable(organisationId, variableId) {
  return del(`ui/manage/${organisationId}/variables/${variableId}`);
}

export function getVariablesForMarket(organisationId, marketId) {
  return get(`ui/play/${organisationId}/markets/${marketId}/variables`);
}

export function getPricesForMarket(organisationId, marketId) {
  return get(`ui/play/${organisationId}/markets/${marketId}/prices`);
}

export function settleMarket(organisationId, marketId, eventIds) {
  return post(`ui/play/${organisationId}/markets/${marketId}/settle`, { eventIds });
}

export function getQuote(organisationId, contractQuantities) {
  return post(`ui/play/${organisationId}/quotes`, contractQuantities);
}

export function createRealTimeOrder(organisationId, order) {
  return post(`ui/play/${organisationId}/orders/real-time`, order);
}

export function getRealTimeOrder(organisationId, realTimeOrderId) {
  return get(`ui/play/${organisationId}/orders/real-time/${realTimeOrderId}`);
}

export function waitRealTimeOrder(organisationId, realTimeOrderId) {
  return get(`ui/play/${organisationId}/orders/real-time/${realTimeOrderId}/wait`);
}

export function getMarketComments(organisationId, marketId) {
  return get(`ui/play/${organisationId}/markets/${marketId}/comments`);
}

export function createMarketComment(organisationId, marketId, message, anonymise, replyTo) {
  return put(`ui/play/${organisationId}/markets/${marketId}/comments`, { message, anonymise, replyTo });
}

export function editMarketComment(organisationId, marketId, commentId, message) {
  return post(`ui/play/${organisationId}/markets/${marketId}/comments/${commentId}`, { message });
}

export function deleteMarketComment(organisationId, marketId, commentId) {
  return del(`ui/play/${organisationId}/markets/${marketId}/comments/${commentId}`);
}

export function getPublicMarket(organisationId, marketId) {
  return get(`ui/public/${organisationId}/markets/${marketId}`);
}

export function getPublicPricesForMarket(organisationId, marketId) {
  return get(`ui/public/${organisationId}/markets/${marketId}/prices`);
}

export function getPublicVariablesForMarket(organisationId, marketId) {
  return get(`ui/public/${organisationId}/markets/${marketId}/variables`);
}
