import { combineReducers } from 'redux';
import market from './Market/reducer';
import control from './Control/reducer';
import leaderboard from './Leaderboard/reducer';
import accounts from './Accounts/reducer';
import admin from './Admin/reducer';

const rootReducer = combineReducers({
  market,
  control,
  accounts,
  admin,
  leaderboard,
});

export default rootReducer;
