import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { browserHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import { checkHttpStatus } from '@agora/agora-common/utils/authFetch';
import { clearMenuItems } from '@agora/agora-common/actions/menuItemActions';

import { renewToken as callRenewToken } from '../../utils/api';
import { getHttpErrorMessage } from '../../utils/http';
import actionTypes from './authActionsTypes';

export function loginUserSuccess() {
  return {
    type: actionTypes.LOGIN_USER_SUCCESS,
  };
}

function loginUserSuccessAndSetToken(token) {
  localStorage.setItem('token', token);
  return loginUserSuccess();
}

function customLoginErrorText(statusCode) {
  switch (statusCode) {
    case 401:
      return 'Invalid username or password';
    case 429:
      return 'Too many logins attempted, please wait a few minutes.';
    default:
      return null;
  }
}

export function loginUserFailure(error) {
  localStorage.removeItem('token');
  return {
    type: actionTypes.LOGIN_USER_FAILURE,
    payload: {
      statusText: getHttpErrorMessage(error, customLoginErrorText),
    },
  };
}

function loginUserRequest() {
  return {
    type: actionTypes.LOGIN_USER_REQUEST,
  };
}

export function logout() {
  localStorage.removeItem('token');
  return {
    type: actionTypes.LOGOUT_USER,
  };
}

export function logoutAndRedirect() {
  return (dispatch) => {
    dispatch(logout());
    dispatch(clearMenuItems());
    browserHistory.push('/login');
  };
}

/*
 * Checks whether the user token contains the membership (in the format {org:role}).
 * This will return true if no membership is specified or if the user is a global admin.
 */
function userHasClaim(token, requiredMembership) {
  const claims = jwtDecode(token);
  const memberships = claims.memberships || [];
  const isAgoraAdmin = claims['agora-admin'] === 'true';

  if (isAgoraAdmin || !requiredMembership) {
    // No specific membership was required
    return true;
  }

  return memberships.includes(requiredMembership);
}

function attemptLogin(dispatch, token, claim, redirect) {
  try {
    if (userHasClaim(token, claim)) {
      dispatch(loginUserSuccessAndSetToken(token));
      if (redirect) {
        browserHistory.push(redirect);
      }
    } else {
      dispatch(loginUserFailure({
        response: {
          status: 403,
          statusText: 'Access denied',
        },
      }));
    }
  } catch (e) {
    dispatch(loginUserFailure({
      response: {
        status: 403,
        statusText: 'Invalid token',
      },
    }));
  }
}

export function loginUser(email, password, claim, redirect = '/') {
  return (dispatch, getState) => {
    dispatch(showLoading());
    dispatch(loginUserRequest());
    const { config } = getState();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return fetch(`${config.baseUrl}/ui/auth/login`, {
      method: 'post',
      mode: 'cors',
      body: formData,
    })
      .then(checkHttpStatus)
      .then(response => response.json())
      .then(json => attemptLogin(dispatch, json.token, claim, redirect))
      .then(() => dispatch(hideLoading()))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(loginUserFailure(error));
        dispatch(hideLoading());
        throw error;
      });
  };
}

export function renewToken() {
  return dispatch => callRenewToken()
    .then((response) => {
      attemptLogin(dispatch, response.token);
      return new Promise(resolve => resolve());
    });
}

export function verifyLogin() {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logoutAndRedirect());
    } else {
      const tokenExpiry = jwtDecode(token).exp;
      if (tokenExpiry > new Date().getTime() / 1000) {
        dispatch(loginUserSuccess(token));
      } else {
        dispatch(logoutAndRedirect());
      }
    }
  };
}

function verifyEmailFailure() {
  return {
    type: actionTypes.VERIFY_EMAIL_FAILURE,
  };
}

function verifyEmailSuccess() {
  return {
    type: actionTypes.VERIFY_EMAIL_SUCCESS,
  };
}

export function verifyEmail(email, key) {
  if (!email || !key) {
    // Nothing to verify, just forward to login
    browserHistory.push('/login');
  }

  return (dispatch, getState) => {
    const { config } = getState();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('key', key);

    return fetch(`${config.baseUrl}/ui/auth/email/verify`, {
      method: 'post',
      mode: 'cors',
      body: formData,
    })
      .then(checkHttpStatus)
      .then(() => dispatch(verifyEmailSuccess()))
      .catch(error => dispatch(verifyEmailFailure(error)));
  };
}