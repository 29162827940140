import {
  auth, config, menuItems, loadingBar,
} from '@agora/agora-common/reducers';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import recycleState from 'redux-recycle';
import recycle from './recycle';
import authReducer from './Auth/authReducer';
import entities from './Entities/entitiesReducer';
import ui from './Ui/uiReducer';
import claims from './Auth/Claims/claimsReducer';
import permissions from './Permissions/reducer';
import createMarket from './CreateMarket/reducer';
import profile from './Profile/reducer';

const rootReducer = combineReducers({
  auth,
  authReducer,
  config,
  form,
  menuItems,
  ui,
  entities,
  loadingBar,
  claims,
  permissions,
  profile,
  createMarket,
});

export default recycleState(rootReducer, ['LOGOUT_USER'], recycle);
