import { connect } from 'react-redux';
import { verifyEmail } from '../../../actions/Auth/authActions';
import VerifyEmail from './VerifyEmail';

function mapStateToProps(state, ownProps) {
  const props = state.authReducer.verifyEmailReducer;
  return {
    subText: ownProps.subText,
    showSuccess: props.showSuccess,
    showError: props.showError,
    resultText: props.resultText,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    verifyEmail() {
      const { email, key } = ownProps.location.query;
      dispatch(verifyEmail(email, key));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
