import actionTypes from '../../../../../../actions/Ui/Views/Market/MarketPanel/MarketTable/actionTypes';

const initialState = {
  isLoading: false,
  selectedRow: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SELECT_CONTRACT_ROW: {
      const { contractId } = action.payload;
      return {
        ...state,
        selectedRow: {
          type: 'CONTRACT',
          contractId,
        },
      };
    }
    case actionTypes.SELECT_TOTAL_ROW: {
      return {
        ...state,
        selectedRow: {
          type: 'TOTAL',
        },
      };
    }
    case actionTypes.DESELECT_ROW: {
      return {
        ...state,
        selectedRow: null,
      };
    }
    default:
      return state;
  }
}
