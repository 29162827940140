import { combineReducers } from 'redux';
import markets from './Market/reducer';
import marketGroups from './MarketGroup/reducer';
import marketSpaces from './MarketSpace/reducer';
import marketStates from './MarketState/reducer';
import variables from './Variable/reducer';
import accounts from './Account/reducer';
import accountPnl from './AccountPnl/reducer';
import contractValuations from './ContractValuation/reducer';
import organisations from './Organisation/reducer';
import partitions from './Partition/reducer';
import contracts from './Contract/reducer';
import prices from './Prices/reducer';
import leaderboard from './Leaderboard/reducer';
import invitations from './Invitations/reducer';
import settlementSummaries from './SettlementSummary/reducer';

const rootReducer = combineReducers({
  markets,
  marketGroups,
  marketSpaces,
  marketStates,
  variables,
  accounts,
  accountPnl,
  contractValuations,
  organisations,
  partitions,
  contracts,
  prices,
  leaderboard,
  invitations,
  settlementSummaries,
});

export default rootReducer;
