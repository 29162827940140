export default {
  DESTROY: 'MARKET_REDUX_HISTOGRAM_DESTROY',
  SET_MARKET: 'MARKET_REDUX_HISTOGRAM_SET_MARKET',
  SET_VARIABLE: 'MARKET_REDUX_HISTOGRAM_SET_VARIABLE',
  SET_SELECTABLE: 'MARKET_REDUX_HISTOGRAM_SET_SELECTABLE',
  SET_VALUES: 'MARKET_REDUX_HISTOGRAM_SET_VALUES',
  SET_VALUE_RANGE: 'MARKET_REDUX_HISTOGRAM_SET_VALUE_RANGE',
  SET_CANVAS_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_CANVAS_SIZE',
  SET_CHART_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_CHART_SIZE',
  SET_SCALING_FACTOR: 'MARKET_REDUX_HISTOGRAM_SET_SCALING_FACTOR',
  SET_X_AXIS_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_X_AXIS_SIZE',
  SET_Y_AXIS_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_Y_AXIS_SIZE',
  SET_X_AXIS_LABEL_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_X_AXIS_LABEL_SIZE',
  SET_Y_AXIS_LABEL_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_Y_AXIS_LABEL_SIZE',
  SET_X_AXIS_TICK_LABELS_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_X_AXIS_TICK_LABELS_SIZE',
  SET_Y_AXIS_TICK_LABELS_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_Y_AXIS_TICK_LABELS_SIZE',
  SET_BODY_SIZE: 'MARKET_REDUX_HISTOGRAM_SET_BODY_SIZE',
  SET_EVENT_POSITIONS: 'MARKET_REDUX_HISTOGRAM_SET_EVENT_POSITIONS',
  SET_Y_AXIS_VALUES: 'MARKET_REDUX_HISTOGRAM_SET_Y_AXIS_VALUES',
  SET_TOOLTIP: 'MARKET_REDUX_HISTOGRAM_SET_TOOLTIP',
  SET_Y_AXIS_LABEL: 'MARKET_REDUX_HEATMAP_SET_Y_AXIS_LABEL',
  CLEAR_SELECTED: 'MARKET_REDUX_HISTOGRAM_CLEAR_SELECTED',
  START_SELECTION_BATCH: 'MARKET_REDUX_HISTOGRAM_START_SELECTION_BATCH',
  UPDATE_SELECTION_BATCH: 'MARKET_REDUX_HISTOGRAM_UPDATE_SELECTION_BATCH',
  COMMIT_SELECTION_BATCH: 'MARKET_REDUX_HISTOGRAM_COMMIT_SELECTION_BATCH',
};
