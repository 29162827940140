import { combineReducers } from 'redux';
import view from './viewReducer';
import marketSpace from './MarketSpace/reducer';
import marketGroup from './MarketGroup/reducer';
import variable from './Variable/reducer';
import market from './Market/reducer';

const rootReducer = combineReducers({
  view,
  marketSpace,
  marketGroup,
  variable,
  market,
});

export default rootReducer;
