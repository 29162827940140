export default {
  DESTROY: 'MARKET_REDUX_HEATMAP_DESTROY',
  SET_MARKET: 'MARKET_REDUX_HEATMAP_SET_MARKET',
  SET_X_VARIABLE: 'MARKET_REDUX_HEATMAP_SET_X_VARIABLE',
  SET_Y_VARIABLE: 'MARKET_REDUX_HEATMAP_SET_Y_VARIABLE',
  SET_SELECTABLE: 'MARKET_REDUX_HEATMAP_SET_SELECTABLE',
  SET_VALUES: 'MARKET_REDUX_HEATMAP_SET_VALUES',
  SET_VALUE_RANGE: 'MARKET_REDUX_HEATMAP_SET_VALUE_RANGE',
  SET_CANVAS_SIZE: 'MARKET_REDUX_HEATMAP_SET_CANVAS_SIZE',
  SET_GRID_SIZE: 'MARKET_REDUX_HEATMAP_SET_GRID_SIZE',
  SET_SCALING_FACTOR: 'MARKET_REDUX_HEATMAP_SET_SCALING_FACTOR',
  SET_X_AXIS_SIZE: 'MARKET_REDUX_HEATMAP_SET_X_AXIS_SIZE',
  SET_Y_AXIS_SIZE: 'MARKET_REDUX_HEATMAP_SET_Y_AXIS_SIZE',
  SET_X_AXIS_LABEL_SIZE: 'MARKET_REDUX_HEATMAP_SET_X_AXIS_LABEL_SIZE',
  SET_Y_AXIS_LABEL_SIZE: 'MARKET_REDUX_HEATMAP_SET_Y_AXIS_LABEL_SIZE',
  SET_X_AXIS_TICK_LABELS_SIZE: 'MARKET_REDUX_HEATMAP_SET_X_AXIS_TICK_LABELS_SIZE',
  SET_Y_AXIS_TICK_LABELS_SIZE: 'MARKET_REDUX_HEATMAP_SET_Y_AXIS_TICK_LABELS_SIZE',
  SET_BODY_SIZE: 'MARKET_REDUX_HEATMAP_SET_BODY_SIZE',
  SET_X_EVENT_POSITIONS: 'MARKET_REDUX_HEATMAP_SET_X_EVENT_POSITIONS',
  SET_Y_EVENT_POSITIONS: 'MARKET_REDUX_HEATMAP_SET_Y_EVENT_POSITIONS',
  SET_TOOLTIP: 'MARKET_REDUX_HEATMAP_SET_TOOLTIP',
  SET_Z_AXIS_LABEL: 'MARKET_REDUX_HEATMAP_SET_Z_AXIS_LABEL',
  CLEAR_SELECTED: 'MARKET_REDUX_HEATMAP_CLEAR_SELECTED',
  START_SELECTION_BATCH: 'MARKET_REDUX_HEATMAP_START_SELECTION_BATCH',
  UPDATE_SELECTION_BATCH: 'MARKET_REDUX_HEATMAP_UPDATE_SELECTION_BATCH',
  COMMIT_SELECTION_BATCH: 'MARKET_REDUX_HEATMAP_COMMIT_SELECTION_BATCH',
};
