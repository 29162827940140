import Loadable from 'react-loadable';
import { ModuleLoadingPage } from '@agora/agora-common/Loading';

const Accounts = Loadable({
  loader: () => import('./AccountsContainer'),
  timeout: 60000,
  loading: ModuleLoadingPage,

});

export default Accounts;
