import actionTypes from '../../../actions/Entities/Market/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
  };
}

function reduce(state, market) {
  const nextState = { ...state };
  if (!nextState.ids.includes(market.id)) {
    nextState.ids.push(market.id);
  }
  nextState.byId[market.id] = market;
  return nextState;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { markets } = action.payload;
      return markets.reduce(reduce, getInitialState());
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { markets } = action.payload;
      return markets.reduce(reduce, state);
    }
    case actionTypes.FETCHED_SINGLE: {
      const { market } = action.payload;
      return reduce(state, market);
    }
    case actionTypes.DELETED_SINGLE: {
      const { marketId } = action.payload;
      const nextState = { ...state };
      if (nextState.ids.includes(marketId)) {
        const idx = nextState.ids.indexOf(marketId);
        nextState.ids.splice(idx, 1);
      }
      if (nextState.byId[marketId]) {
        delete nextState.byId[marketId];
      }
      return nextState;
    }
    default:
      return state;
  }
}
