import { parseLeaderboardVisibility } from '../../../../../utils/leaderboard';
import actionTypes from '../../../../../actions/Control/actionTypes';
import { getInitialState } from '../../../../CreateMarket/reducer';

function getCurrentState(entities) {
  const context = getInitialState();
  const { wizardState } = context;
  const { market } = entities;
  const { marketSpace } = entities;
  const { variable } = entities;

  // Organisation
  wizardState.organisation = { id: market.organisationId };

  // Market / Market Space
  wizardState.market.name = market.name;
  wizardState.market.description = market.description;
  wizardState.market.instructions = market.instructions;
  wizardState.market.liquidity = market.parameters.liquidityFactor;
  wizardState.market.startingCredits = marketSpace.parameters.startingCredits;

  // Variable
  wizardState.variable.name = variable.name;
  if (variable.type === 'Continuous') {
    wizardState.variable.outcomeType = 'numerical';

    const { numerical } = wizardState.variable;
    const first = variable.events[0];
    const last = variable.events[variable.events.length - 1];
    const divisions = variable.events.length - (numerical.extendBelowMin ? 1 : 0) - (numerical.extendAboveMax ? 1 : 0);

    numerical.extendBelowMin = first.low === null;
    numerical.extendAboveMax = last.high === null;
    numerical.minVal = numerical.extendBelowMin ? first.high.value : first.low.value;
    numerical.maxVal = numerical.extendAboveMax ? last.low.value : last.high.value;
    numerical.intervals = (numerical.maxVal - numerical.minVal) / divisions;
    numerical.symbol = variable.unit.symbol;
  } else {
    wizardState.variable.outcomeType = 'nonnumerical';
    variable.events.forEach(e => wizardState.variable.nonNumerical.values.push(e.value));
  }

  // Leaderboard
  const leaderboardVisibility = parseLeaderboardVisibility(marketSpace.parameters.leaderboardVisibility);
  wizardState.leaderboard.boardVisible = !!leaderboardVisibility.length;
  if (wizardState.leaderboard.boardVisible) {
    wizardState.leaderboard.namesVisible = leaderboardVisibility.includes('Name');
    wizardState.leaderboard.valuationsVisible = leaderboardVisibility.includes('Credits');
    wizardState.leaderboard.numPlayersVisible = marketSpace.parameters.leaderboardMaxPlayers ? marketSpace.parameters.leaderboardMaxPlayers : 0;
  }

  // Market Comments
  if (market.options) {
    wizardState.options.useComments = market.options.useComments;
    wizardState.options.allowAnonymousComments = market.options.allowAnonymousComments;
    wizardState.options.commentNotifications = market.options.commentNotifications;
  }

  return context;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.UPDATE_MARKET_INITIALISE: {
      return {
        ...getCurrentState(action.payload),
        initialised: true,
      };
    }
    case actionTypes.UPDATE_MARKET_REQUEST: {
      return {
        ...state,
        progress: {
          state: 'inprogress',
          statusTitle: 'Please wait',
          statusText: 'Saving your changes',
        },
      };
    }
    case actionTypes.UPDATE_MARKET_SUCCESS: {
      return {
        ...state,
        progress: {
          state: 'success',
          statusTitle: 'Saved',
          statusText: 'Your changes have been saved successfully',
        },
      };
    }
    case actionTypes.UPDATE_MARKET_FAILURE: {
      return {
        ...state,
        progress: {
          state: 'error',
          statusTitle: 'Error',
          statusText: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
