import actionTypes from '../../../../../../actions/Ui/Views/Control/ControlPanel/SettlementControl/actionTypes';

const initialState = {
  isSettling: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SETTLING:
      return {
        ...state,
        isSettling: true,
      };
    case actionTypes.SETTLED:
      return {
        ...state,
        isSettling: false,
      };
    default:
      return state;
  }
}
