import { combineReducers } from 'redux';
import view from './viewReducer';
import pnl from './Pnl/reducer';
import contractValuations from './ContractValuations/reducer';

const rootReducer = combineReducers({
  view,
  pnl,
  contractValuations,
});

export default rootReducer;
