import { userHasClaim, isValidToken } from '@agora/agora-common/utils/authUtils';
import jwtDecode from 'jwt-decode';
import actionTypes from './claimsActionTypes';
import claimTypes from './claimTypes';

function setIsAgoraAdmin() {
  const token = localStorage.getItem('token');
  let isAgoraAdmin = false;

  if (isValidToken(token)) {
    const decodedToken = jwtDecode(token);
    isAgoraAdmin = decodedToken[claimTypes.CLAIM_AGORA_ADMIN_NAME] === 'true';
  }

  return {
    type: actionTypes.SET_HAS_CLAIM,
    payload: {
      name: claimTypes.CLAIM_AGORA_ADMIN_NAME,
      hasClaim: isAgoraAdmin,
    },
  };
}

function setRoles(permission) {
  const token = localStorage.getItem('token');
  let orgs = [];

  if (isValidToken(token)) {
    const decodedToken = jwtDecode(token);
    let { memberships } = decodedToken;

    if (!memberships) {
      memberships = [];
    }

    if (!Array.isArray(memberships)) {
      memberships = [memberships];
    }

    const filteredMemberships = memberships.map(m => m.split(':')).filter(m => m[1] === permission);
    orgs = filteredMemberships.map(m => m[0]);
  }

  return {
    type: actionTypes.SET_ORG_ROLE,
    payload: {
      role: permission,
      organisations: orgs,
    },
  };
}

// eslint-disable-next-line import/prefer-default-export
export function setHasClaim(name) {
  switch (name) {
    case claimTypes.CLAIM_AGORA_ADMIN_NAME:
      return setIsAgoraAdmin();
    case claimTypes.ROLE_ORGADMIN:
    case claimTypes.ROLE_OPERATOR:
    case claimTypes.ROLE_BASIC:
      return setRoles(name);
    default:
      break;
  }

  const hasClaim = userHasClaim(name);

  return {
    type: actionTypes.SET_HAS_CLAIM,
    payload: {
      name,
      hasClaim,
    },
  };
}