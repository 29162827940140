import { combineReducers } from 'redux';
import edit from './Edit/reducer';
import view from './viewReducer';
import controlPanel from './ControlPanel/reducer';

const rootReducer = combineReducers({
  edit,
  view,
  controlPanel,
});

export default rootReducer;
