import actionTypes from '../../../../../../../actions/Ui/Views/Market/MarketPanel/MarketTable/MarketTableRow/actionTypes';

const initialState = {
  isLoadingQuoteForContractId: {},
  isPlacingOrderForContractId: {},
  isDeletingContract: {},
  orderQuantitiesForContractId: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_QUOTE: {
      const { contractId } = action.payload;
      const newState = { ...state };
      newState.isLoadingQuoteForContractId[contractId] = true;
      return newState;
    }
    case actionTypes.LOADED_QUOTE: {
      const { contractId } = action.payload;
      const newState = { ...state };
      newState.isLoadingQuoteForContractId[contractId] = false;
      return newState;
    }
    case actionTypes.PLACING_ORDER: {
      const { contractId } = action.payload;
      const newState = { ...state };
      newState.isPlacingOrderForContractId[contractId] = true;
      return newState;
    }
    case actionTypes.PLACED_ORDER: {
      const { contractId } = action.payload;
      const newState = { ...state };
      newState.isPlacingOrderForContractId[contractId] = false;
      return newState;
    }
    case actionTypes.DELETING_CONTRACT: {
      const { contractId } = action.payload;
      const newState = { ...state };
      newState.isDeletingContract[contractId] = true;
      return newState;
    }
    case actionTypes.DELETED_CONTRACT: {
      const { contractId } = action.payload;
      const newState = { ...state };
      newState.isDeletingContract[contractId] = false;
      return newState;
    }
    case actionTypes.SET_ORDER_QUANTITY: {
      const { contractId, quantity } = action.payload;
      const newState = { ...state };
      newState.orderQuantitiesForContractId[contractId] = quantity;
      return newState;
    }
    default:
      return state;
  }
}
