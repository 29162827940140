import { getPermissions } from '../../utils/api';
import actionTypes from './actionTypes';

export function fetchedAll(permissions) {
  return {
    type: actionTypes.FETCHED_ALL,
    payload: {
      permissions,
    },
  };
}

export function fetchPermissions() {
  return dispatch => getPermissions()
    .then(permissions => dispatch(fetchedAll(permissions)));
}
