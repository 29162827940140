import actionTypes from '../../../../../actions/Ui/Views/Market/MarketPanel/actionTypes';

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOADED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
