import actionTypes from '../../../actions/Entities/SettlementSummary/actionTypes';

function getInitialState() {
  return {
    byMarketId: {},
  };
}

function reduce(state, marketId, sumamry) {
  const next = { ...state };
  next.byMarketId[marketId] = sumamry;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_SINGLE: {
      const { summary } = action.payload;
      return reduce(state, summary.marketId, summary);
    }
    default:
      return state;
  }
}
