import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles, withTheme } from '@material-ui/core/styles';

import * as colours from '../../../../styles/colours';

const styles = theme => ({
  root: {
    borderColor: theme.palette.primary.main,
    borderRadius: '0.9em',
  },
  errorRoot: {
    borderColor: colours.errorTextColour,
  },
  helperText: {
    color: colours.errorTextColour,
  },
  content: {
    padding: '0 0.8em',
  },
});

function GroupBox({
  classes,
  error,
  helperText,
  title,
  children,
}) {
  return (
    <fieldset className={classNames(classes.root, error ? classes.errorRoot : null)}>
      {title && <legend>{title}</legend>}
      <div className={classNames(classes.content)}>
        {!error || <p className={classNames(classes.helperText)}>{helperText}</p>}
        {children}
      </div>
    </fieldset>
  );
}

GroupBox.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  title: PropTypes.string,
};

GroupBox.defaultProps = {
  error: false,
  helperText: '',
  title: '',
};

export default withStyles(styles)(withTheme(GroupBox));