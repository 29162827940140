import actionTypes from '../../../../../../actions/Ui/Views/Admin/MarketSpace/Creator/actionTypes';

const initialState = {
  isCreating: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATING:
      return {
        ...state,
        isCreating: true,
      };
    case actionTypes.CREATED:
      return {
        ...state,
        isCreating: false,
      };
    default:
      return state;
  }
}
