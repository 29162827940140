/* eslint no-param-reassign: 0 */
import actionTypes from '../../../../../actions/Ui/Entities/Market/ReduxHeatmap/actionTypes';

const getInitialState = () => ({});

const getInitialHeatmapState = id => ({
  id,
  market: null,
  xVariable: null,
  yVariable: null,
  selectable: false,
  values: {},
  valueRange: { min: 0, max: 1 },
  canvasSize: null,
  gridSize: null,
  scalingFactor: null,
  xAxisSize: null,
  yAxisSize: null,
  xAxisLabelSize: null,
  yAxisLabelSize: null,
  xAxisTickLabelsSize: null,
  yAxisTickLabelsSize: null,
  tooltip: null,
  zAxisLabel: null,
  bodySize: null,
  xEventPositions: null,
  yEventPositions: null,
  selected: {},
  selectionBatch: null,
});

const validActionTypes = Object.values(actionTypes);

function reduce(heatmapId, state, action) {
  switch (action.type) {
    case actionTypes.SET_MARKET: {
      const { market } = action.payload;
      return {
        ...state,
        market,
      };
    }
    case actionTypes.SET_X_VARIABLE: {
      const { variable } = action.payload;
      return {
        ...state,
        xVariable: variable,
      };
    }
    case actionTypes.SET_Y_VARIABLE: {
      const { variable } = action.payload;
      return {
        ...state,
        yVariable: variable,
      };
    }
    case actionTypes.SET_SELECTABLE: {
      const { selectable } = action.payload;
      return {
        ...state,
        selectable: !!selectable,
      };
    }
    case actionTypes.SET_VALUES: {
      const { values } = action.payload;
      return {
        ...state,
        values,
      };
    }
    case actionTypes.SET_VALUE_RANGE: {
      const { range } = action.payload;
      return {
        ...state,
        valueRange: range,
      };
    }
    case actionTypes.SET_CANVAS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        canvasSize: size,
      };
    }
    case actionTypes.SET_GRID_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        gridSize: size,
      };
    }
    case actionTypes.SET_SCALING_FACTOR: {
      const { factor } = action.payload;
      return {
        ...state,
        scalingFactor: factor,
      };
    }
    case actionTypes.SET_X_AXIS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        xAxisSize: size,
      };
    }
    case actionTypes.SET_Y_AXIS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        yAxisSize: size,
      };
    }
    case actionTypes.SET_X_AXIS_LABEL_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        xAxisLabelSize: size,
      };
    }
    case actionTypes.SET_Y_AXIS_LABEL_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        yAxisLabelSize: size,
      };
    }
    case actionTypes.SET_X_AXIS_TICK_LABELS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        xAxisTickLabelsSize: size,
      };
    }
    case actionTypes.SET_Y_AXIS_TICK_LABELS_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        yAxisTickLabelsSize: size,
      };
    }
    case actionTypes.SET_BODY_SIZE: {
      const { size } = action.payload;
      return {
        ...state,
        bodySize: size,
      };
    }
    case actionTypes.SET_X_EVENT_POSITIONS: {
      const { positions } = action.payload;
      return {
        ...state,
        xEventPositions: positions,
      };
    }
    case actionTypes.SET_Y_EVENT_POSITIONS: {
      const { positions } = action.payload;
      return {
        ...state,
        yEventPositions: positions,
      };
    }
    case actionTypes.SET_TOOLTIP: {
      const { tooltip } = action.payload;
      return {
        ...state,
        tooltip,
      };
    }
    case actionTypes.SET_Z_AXIS_LABEL: {
      const { zAxisLabel } = action.payload;
      return {
        ...state,
        zAxisLabel,
      };
    }
    case actionTypes.CLEAR_SELECTED: {
      return {
        ...state,
        selected: {},
        selectionBatch: null,
      };
    }
    case actionTypes.START_SELECTION_BATCH: {
      const { selecting } = action.payload;
      return {
        ...state,
        selectionBatch: {
          selecting,
          batch: { xEventIds: [], yEventIds: [] },
        },
      };
    }
    case actionTypes.UPDATE_SELECTION_BATCH: {
      const { batch } = action.payload;
      return {
        ...state,
        selectionBatch:
          state.selectionBatch !== null
            ? {
              selecting: state.selectionBatch.selecting,
              batch,
            }
            : null,
      };
    }
    case actionTypes.COMMIT_SELECTION_BATCH: {
      const nextState = { ...state };
      if (state.selectionBatch) {
        const newSelected = nextState.selected;
        state.selectionBatch.batch.xEventIds.forEach((xEventId) => {
          state.selectionBatch.batch.yEventIds.forEach((yEventId) => {
            newSelected[`${xEventId}_${yEventId}`] = state.selectionBatch.selecting;
          });
        });
        return {
          ...nextState,
          selected: newSelected,
          selectionBatch: null,
        };
      }
      return nextState;
    }
    default: {
      return state;
    }
  }
}

export default function (state = getInitialState(), action) {
  if (action.type === actionTypes.DESTROY) {
    const { heatmapId } = action.payload;
    const nextState = { ...state };
    delete nextState[heatmapId];
    return nextState;
  }

  if (validActionTypes.includes(action.type)) {
    const { heatmapId } = action.payload;
    const heatmapState = state[heatmapId] || getInitialHeatmapState(heatmapId);
    const nextState = { ...state };
    nextState[heatmapId] = reduce(heatmapId, heatmapState, action);
    return nextState;
  }

  return state;
}
