import actionTypes from '../../../../../../actions/Ui/Views/Control/ControlPanel/StateControl/actionTypes';

const initialState = {
  isUpdatingRealTimeOrderState: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATING_REAL_TIME_ORDER_STATE:
      return {
        ...state,
        isUpdatingRealTimeOrderState: true,
      };
    case actionTypes.UPDATED_REAL_TIME_ORDER_STATE:
      return {
        ...state,
        isUpdatingRealTimeOrderState: false,
      };
    default:
      return state;
  }
}
