import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = () => ({
  spinner: {
    display: 'block',
    position: 'relative',
  },
  spinnerOffset: {
    display: 'inline',
    position: 'absolute',
    left: '-25px',
  },
});

function TinyLoadingIndicator({ message, classes }) {
  return (
    <div style={{ height: '100%', flex: 'inherit' }} className="row middle-xs">
      <div
        className="row middle-xs center-xs"
        style={{ margin: '0 auto', position: 'relative' }}
      >
        {
          message
            ? <CircularProgress size={16} className={classNames(classes.spinnerOffset)} />
            : <CircularProgress size={16} className={classNames(classes.spinner)} />
        }
        <span>{message}</span>
      </div>
    </div>
  );
}

TinyLoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
};

TinyLoadingIndicator.defaultProps = {
  message: '',
};

export default withStyles(styles)(withTheme(TinyLoadingIndicator));