import actionTypes from '../../../actions/Entities/Organisation/actionTypes';
import { getOperatorMemberships } from '../../../utils/claims';

function getInitialState() {
  return {
    ids: [],
    byId: {},
    operatorOrganisations: [],
  };
}

function reduce(state, organisation) {
  const next = { ...state };

  if (!next.ids.includes(organisation.id)) {
    next.ids.push(organisation.id);
  }

  next.byId[organisation.id] = organisation;

  const operatorOrg = getOperatorMemberships().filter(m => m.orgId === organisation.id);
  if (operatorOrg && operatorOrg.length) {
    const role = operatorOrg[0];
    next.operatorOrganisations.push({
      id: role.orgId,
      name: organisation.name,
    });
  }

  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { organisations } = action.payload;
      return organisations.reduce(reduce, getInitialState());
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { organisations } = action.payload;
      return organisations.reduce(reduce, state);
    }
    case actionTypes.FETCHED_SINGLE: {
      const { organisation } = action.payload;
      return reduce(state, organisation);
    }
    default:
      return state;
  }
}
