import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import FormHolder from '@agora/agora-common/FormHolder/FormHolder';

export default class VerifyEmail extends React.Component {
  render() {
    if (!this.props.showSuccess
      && !this.props.showError) {
      this.props.verifyEmail();
    }

    return (
      <FormHolder subText={this.props.subText}>
        <div>
          <h1>Email Verification</h1>
          <ResultText success={this.props.showSuccess} failure={this.props.showError} />
          <div>
            <Link to="/login">
              Go to login
            </Link>
          </div>
        </div>
      </FormHolder>
    );
  }
}

function ResultText(props) {
  if (!props.success && !props.failure) {
    return <p>Verifying your Email...</p>;
  }

  if (props.success) {
    return <p>Thank you for verifying your Email address</p>;
  }

  if (props.failure) {
    return (
      <p>
        We were unable to verify your Email address. Please contact
        <a href="mailto:support@hvmd.io">support@hvmd.io</a>
      </p>
    );
  }
}

VerifyEmail.propTypes = {
  showError: PropTypes.bool,
  showSuccess: PropTypes.bool,
  subText: PropTypes.string,
  verifyEmail: PropTypes.func.isRequired,
};

VerifyEmail.defaultProps = {
  showError: false,
  showSuccess: false,
  subText: 'PLATFORM',
};
