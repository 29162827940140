import actionTypes from '../../../actions/Entities/Prices/actionTypes';

function getInitialState() {
  return {
    byMarketId: {},
  };
}

function reduce(state, marketId, prices) {
  const next = { ...state };
  next.byMarketId[marketId] = prices;
  return next;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_SINGLE: {
      const { marketId, prices } = action.payload;
      return reduce(state, marketId, prices);
    }
    default:
      return state;
  }
}
