export const Pages = {
  MARKET: 'Market',
  INVITED_SIGN_UP: 'Invited Players Sign Up',
  ACCOUNT: 'Account',
  MANAGE: 'Manage',
  PROFILE: 'Profile',
  ADMIN: 'Admin',
  CHANGE_PASSWORD: 'Change Password',
  FORGOT_PASSWORD: 'Forgot Password',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found',
  PUBLIC_PRICES: 'Public Prices',
};

export const CreateMarketAnalytics = {
  CREATE: 'Create Market',
  NUMERICAL: 'Market Data Numerical',
  NON_NUMERICAL: 'Market Data Non-Numerical',
  MARKET_INFO: 'Market Information',
  DOCUMENTATION: 'Market Documentation',
  INVITE: 'Invite Players',
  LEADERBOARD_OPTIONS: 'Set Leaderboard Options',
  COMMENT_OPTIONS: 'Set Market Comments Options',
  MARKET_OPTIONS: 'Set Market Options',
};