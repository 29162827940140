import {
  defaultInstructions,
  defaultLiquidityFactor,
  defaultSpreadFactor,
  defaultSpreadConstant,
} from '../../components/Views/Admin/Market/Creator/Constants';
import controlActionTypes from '../../actions/Control/actionTypes';

export function getInitialState() {
  return {
    initialized: false,
    progress: {
      state: 'notstarted',
      statusCode: null,
      statusTitle: '',
      statusText: '',
    },
    wizardState: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      organisation: null,
      acceptMailingList: false,
      market: {
        name: '',
        description: '',
        liquidity: defaultLiquidityFactor,
        liquidityChanged: false,
        isOpen: true,
        spreadFactor: defaultSpreadFactor,
        spreadConstant: defaultSpreadConstant,
        startingCredits: 0,
        instructions: defaultInstructions,
      },
      variable: {
        name: '',
        shortName: '',
        outcomeType: 'numerical',
        numerical: {
          symbol: '',
          minVal: 0,
          maxVal: 0,
          intervals: 0,
          extendBelowMin: false,
          extendAboveMax: false,
        },
        nonNumerical: {
          values: [],
        },
      },
      invitation: {
        message: '',
        players: [],
      },
      leaderboard: {
        boardVisible: true,
        namesVisible: true,
        valuationsVisible: true,
        numPlayersVisible: 20,
      },
      options: {
        useComments: true,
        allowAnonymousComments: false,
        commentNotifications: 'NewAndRepliesToThread',
      },
    },
  };
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case controlActionTypes.CREATE_MARKET_RESET: {
      return {
        ...getInitialState(),
      };
    }
    case controlActionTypes.CREATE_MARKET_REQUEST: {
      return {
        ...state,
        progress: {
          state: 'inprogress',
          statusCode: null,
          statusTitle: 'Please wait',
          statusText: 'Creating market',
        },
      };
    }
    case controlActionTypes.INVITE_PLAYERS_REQUEST: {
      return {
        ...state,
        progress: {
          state: 'inprogress',
          statusCode: null,
          statusTitle: 'Please wait',
          statusText: 'Inviting players',
        },
      };
    }
    case controlActionTypes.CREATE_MARKET_SUCCESS: {
      return {
        ...state,
        progress: {
          state: 'success',
          statusCode: null,
          statusTitle: 'All done',
          statusText: 'Congrats! Your market has been created',
        },
      };
    }
    case controlActionTypes.CREATE_MARKET_FAILURE: {
      return {
        ...state,
        progress: {
          state: 'error',
          statusCode: action.payload && action.payload.statusCode ? action.payload.statusCode : null,
          statusTitle: 'Error',
          statusText: action.payload && action.payload.statustext ? action.payload.statustext : 'Something went wrong.',
        },
      };
    }
    default:
      return state;
  }
}
