export const pageBackground = '#1d252d';
export const charcoalGrey = '#2d3640';
export const solidLightGrey = '#f5f5f5';
export const lightGrey = '#8EA0B3';
export const panelGrey = 'rgba(255, 255, 255, 0.1)';
export const sectionBackground = 'rgb(48,54,63)';
export const surfaceBackgroundOne = '#2D3640';
export const menuBackground = '#2E3842';
export const buttonInactiveBackground = '#f5f5f5';
export const deleteButtonColour = 'red';
export const deleteButtonColourHovered = 'rgba(100, 0, 0, 0.4)';
export const completeIconColour = 'green';
export const itemSelected = 'rgba(59, 64, 73, 1)';

// TODO: Move into Hivemind theme
export const errorTextColour = 'red';