import actionTypes from '../../../../../actions/Ui/Views/Accounts/ContractValuations/actionTypes';

const initialState = {
  isLoadingTable: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING_TABLE:
      return {
        ...state,
        isLoadingTable: true,
      };
    case actionTypes.LOADED_TABLE:
      return {
        ...state,
        isLoadingTable: false,
      };
    default:
      return state;
  }
}
