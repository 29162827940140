import actionTypes from '../../../actions/Entities/Account/actionTypes';

function getInitialState() {
  return {
    ids: [],
    byId: {},
    idsByMarketSpaceId: {},
  };
}

function reduce(state, account) {
  const nextState = { ...state };
  if (!nextState.ids.includes(account.id)) {
    nextState.ids.push(account.id);
  }
  nextState.idsByMarketSpaceId[account.marketSpaceId] = account.id;
  nextState.byId[account.id] = account;
  return nextState;
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCHED_ALL: {
      const { accounts } = action.payload;
      return accounts.reduce(reduce, { ...getInitialState() });
    }
    case actionTypes.FETCHED_MULTIPLE: {
      const { accounts } = action.payload;
      return accounts.reduce(reduce, { ...state });
    }
    case actionTypes.FETCHED_SINGLE: {
      const { account } = action.payload;
      return reduce({ ...state }, account);
    }
    default:
      return state;
  }
}
