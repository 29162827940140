import { combineReducers } from 'redux';
import reduxHeatmap from './ReduxHeatmap/reducer';
import reduxHistogram from './ReduxHistogram/reducer';

const reducer = combineReducers({
  reduxHeatmap,
  reduxHistogram,
});

export default reducer;
